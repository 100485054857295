<!--设置管理模块--通知设置管理页--下载-->
<template>
  <div id="downloadSetting" v-loading="loading" :element-loading-text="$t('doorequipment.a12')">
    <!-- 查询栏 -->
    <div class="header">
      <div>
        <a-input v-model="form.downLoadName" :placeholder="$t('downloadSetting.a1')" style="width:180px;margin-right:15px"/>
        <a-input v-model="form.fileName" :placeholder="$t('downloadSetting.a2')" style="width:180px;margin-right:15px"/>
        <a-input v-model="form.updateUser" :placeholder="$t('downloadSetting.a3')" style="width:180px"/>
      </div>
      <div>
        <a-button type="primary" @click="inquire" style="width:120px">{{$t('inquireSetting.a3')}}</a-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="main" ref="main">
        <a-table 
          :row-selection="allrowSelection"
          :columns="columns" 
          :dataSource="data" 
          :pagination="pagination"
          :scroll="size" >           
        </a-table>         
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
      <a-popconfirm
          :title="$t('downloadSetting.a5')"
          :ok-text="$t('downloadSetting.a6')"
          :cancel-text="$t('downloadSetting.a7')"
          @confirm="onDelete"
          @cancel="onCancel"
        >
        <a-button class="but" type="primary">{{$t('downloadSetting.a8')}}</a-button>
      </a-popconfirm>
      <a-button type="primary" class="but" @click="onAdd">{{$t('downloadSetting.a9')}}</a-button>   
      <a-button type="primary" class="but" @click="onChange">{{$t('downloadSetting.a10')}}</a-button>         
    </div> 
    <!-- 添加/变更弹框 -->
    <a-modal 
          v-model="visible" 
          :title="name" 
          width=600px           
          @ok="handleOk"
          @cancel="cancel"
          :destroyOnClose="true"
          v-drag
        >
        <a-form-model ref="ruleForm" :model="infoForm">
          <div class="form_row">            
            <div class="form_col">
              <a-form-model-item :label="$t('downloadSetting.a1')">
                <a-input v-model="infoForm.downLoadName"/>
              </a-form-model-item>
            </div>
          </div>
          <div class="form_row">  
            <div class="form_col">
              <a-form-model-item  :label="$t('downloadSetting.a2')">
                <a-upload
                  ref="upload"
                  :name="infoForm.fileName"               
                  :before-upload="beforeUpload"
                  :fileList="fileList"
                  @change="handleChange"
                >
                  <a-button> <a-icon type="upload" /> {{$t('downloadSetting.a23')}} </a-button>
                </a-upload>
              </a-form-model-item>
            </div>
            <div class="form_col">
              <a-button
                type="primary"
                @click="fileUpload"
                :disabled="disabled"
                >{{ $t("downloadSetting.a23") }}</a-button
              >
            </div>
          </div>                           
      </a-form-model>
      </a-modal>  
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import headerTitle from "../../../components/Title/headerTitleA";
import {getInformation,deleteInformation,addInformation,updateInformation,uploadFile,pushMsg} from "../../../api/sysmanage";

export default { 
  data() {
    return {
      disabled:false,
      name: this.$t('downloadSetting.a11'),
      visible: false,//添加与变更弹窗是否显示,
      data: [],
      loading: false, 
      size:{ x: 1300, y:500},     
      siteId:0,
      selectedOption:{},
      selectedOptions:[],
      form: {
        siteId: 0,
        updateUser: "",
        downLoadName:"",
        fileName:"",
      },
      infoForm:{
        key:0,
        oldId:"0",//旧信息ID
        downLoadName: "",//下载名称
        fileName:"",//文件名称
        lastUpdate:"",//最后更新日期        
        isAdd:true,//是否新增        
      },
      file:{},
      fileList:[],
      rules: {
        mainUser: [
          { required: true, message: 'Please input mainUser name', trigger: 'blur' },
        ],        
      },   
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t("downloadSetting.a12")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },      
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 80,
          ellipsis: true,  
          // scopedSlots: { customRender: "id" },
        },
        {
          title: this.$t('downloadSetting.a13'),
          dataIndex: "addMod",
          width: 100,
          ellipsis: true,          
        },
        {
          title: this.$t('downloadSetting.a14'),
          dataIndex: "lastUpdate",
          width: 100,
          ellipsis: true,         
        },
        {
          title: this.$t('downloadSetting.a15'),
          dataIndex: "updateUser",
          width: 100,
          ellipsis: true,
        },    
        {
          title: this.$t('downloadSetting.a1'),
          dataIndex: "downLoadName",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('downloadSetting.a2'),
          dataIndex: "fileName",
          width: 100,
          ellipsis: true,
        },     
        {
          title: this.$t('downloadSetting.a16'),
          dataIndex: "showName",
          width: 100,
          ellipsis: true,
        },                   
      ],
    };
  }, 
  mounted(){
    this.inquire();//退出更新窗口后，刷新数据 
  },
  computed:{
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log("downloadSetting selectedRowKeys:", selectedRowKeys);
          console.log("downloadSetting selectedRows:", selectedRows);
          if(selectedRows!=null&&selectedRows.length>0){  
            this.selectedOptions = selectedRows;
            this.selectedOption = selectedRows[0];//取第一个选择项
          }
          // console.log("downloadSetting selectedOption:", this.selectedOption);
        },
      };
    },
  },
  methods: {  
    beforeUpload(file) {
      if(file!=null){
        this.file=file;
      }
      console.log("beforeUpload file:", file);
      return false;
    },
    handleChange(info) {
      this.fileList = info.fileList;
      // if (info.file.status !== 'uploading') {
      //   // console.log(info.file, info.fileList);
      // }
      // if (info.file.status === 'done') {
      //   this.$message.success(`${info.file.name} file uploaded successfully`);
      // } else if (info.file.status === 'error') {
      //   this.$message.error(`${info.file.name} file upload failed.`);
      // }
    },
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("transferSetting");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 66 - title;
        this.size.y = tableHeight;
      }
    },  
    setValue(src){
      if(src==null){     
        this.infoForm.key=0;   
        this.infoForm.oldId = "0";//旧信息ID
        this.infoForm.downLoadName="";//下载名称
        this.infoForm.fileName="";//文件名称
        // this.infoForm.lastUpdate="";//最后更新日期         
      }else{
        this.infoForm.key=src.key;  
        this.infoForm.oldId = src.id;
        this.infoForm.downLoadName=src.downLoadName;//下载名称
        this.infoForm.fileName=src.fileName;//文件名称
        this.infoForm.lastUpdate=src.lastUpdate;//最后更新日期 
      }
    },
    handleOk(){
      console.log("downloadSetting infoForm:", this.infoForm); 
      if(this.infoForm.isAdd){//添加
        this.addInformation();        
      }
      else{//变更
        this.updateInformation();
      }      
    } ,    
    pushMsg(){
      let par={
        type: 0,
        content:"test",
      }
      console.log("pushMsg:",par);
      pushMsg(par)
        .then((res) => {
          let { data } = res;
          // this.$message.info("推送消息成功"); 
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error("推送消息失败"); 
        });   
    }, 
    // 文件上传
    fileUpload() {
      this.loading = true;
      const formData = new FormData();
      // this.fileList.forEach((file) => {
      //   formData.append("file", file);
      // });
      console.log("uploadFile file:", this.file);
      formData.append("file", this.file);
      formData.append("downLoadName", this.infoForm.downLoadName);
      formData.append("fileName",this.file.name);
      formData.append("id",this.infoForm.oldId);
      formData.append("key",this.infoForm.key);
      console.log("uploadFile par:", formData);
      uploadFile(formData)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    cancel(){
      // this.$refs.upload.clearFiles();
      this.fileList.length=0;
      this.inquire();
    },
    onCancel(){

    },
    onDelete() {
      this.deleteInformation();    
      this.inquire();  
    },    
    onAdd() { 
      this.visible = true;  
      this.setValue(null);//初始化页面关联对象
      this.infoForm.isAdd = true;  //是否新增   
    },  

    onChange() {  
      if(this.selectedOptions.length!=1){
        this.$message.error(this.$t('downloadSetting.a24'));
        return;
      }
      this.visible = true;
      this.setValue(this.selectedOption);//初始化页面关联对象
      this.infoForm.isAdd = false;
    },  
    
    // 变更
    updateInformation() {       
      this.loading = true;  
      let par = {
          oldId:this.infoForm.oldId,//旧信息ID
          infoType:4,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          filePar:{
            downloadName:	this.infoForm.downLoadName,
            fileName:this.infoForm.fileName,
            lastUpdate:this.infoForm.lastUpdate,
            num:this.infoForm.oldId,
            node:"",
            file:[],
          }
      };
      console.log("updateInformation par:", par);     
      updateInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateInformation result", data);  
          if(data)        
            this.$message.info(this.$t('downloadSetting.a17')); 
          else
            this.$message.error(this.$t('downloadSetting.a18')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('downloadSetting.a18')+err); 
        }); 
    },
    // 添加
    addInformation() {           
      this.loading = true;  
      let infoPar = {
          oldId:this.infoForm.oldId,//旧信息ID
          infoType:4,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          filePar:{
            downloadName:	this.infoForm.downLoadName,
            fileName:this.infoForm.fileName,
            lastUpdate:this.infoForm.lastUpdate,
            num:"",
            node:"",
            file:[],
          }        
      };
      console.log("addInformation par:", infoPar); 
      addInformation(infoPar)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.pushMsg();
          console.log("addInformation result", data);
          this.$message.info(this.$t('downloadSetting.a19')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('downloadSetting.a20')+err); 
        }); 
    },

    // 删除
    deleteInformation() {     
      this.loading = true;      
      let ids = "4";
      if(this.selectedOptions!=null&&this.selectedOptions.length>0){
        for(let i=0;i<this.selectedOptions.length;i++){
            ids = ids +","+this.selectedOptions[i].id;
        }
      }
      let par = {
        param: ids,
      };
      console.log("deleteInformation par", par);
      deleteInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("deleteInformation result", data);
          this.$message.info(this.$t('downloadSetting.a21')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('downloadSetting.a22')+err); 
        }); 
    },    
    // 条件查询方法
    inquire() {           
      this.loading = true;
      this.tableSize()
      let par = {
        updateUser: this.form.updateUser,
        infoType:4,//1：公共指南；2：咨询处；3：继承事项；4：下载；
        info: this.form.downLoadName+","+this.form.fileName,
      };
      console.log("downloadSetting inquire par:", par); 
      getInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("downloadSetting data", data);
          for (let i = 0; i < data.length; i++) {
            data[i].key = i + 1;
          }
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#downloadSetting {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 20px 0px 20px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.main {
  width: 100%;
  height: calc(100% - 87px);
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.but {
  width: 120px;
  margin-left: 15px;
}
</style>